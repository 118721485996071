<template>
  <div class="payment-sources-form tw-pb-10">
    <div class="tw-flex tw-justify-start tw-items-center">
      <ui-button
        :disabled="$wait.is(loader)"
        :title="$t('account.payment-sources.buttons.add_card')"
        type="button"
        variant="link"
        data-test="toggle-button"
        @click="toggle"
      >
        <template #left-icon>
          <ui-material-icon
            :class="{ 'payment-sources-form__button__icon--collapsed': collapsed }"
            name="add_circle"
            class="payment-sources-form__button__icon"
            data-test="icon"
          />
        </template>
        <span
          v-text="$t('account.payment-sources.buttons.add_card')"
          data-test="content"
        />
      </ui-button>
    </div>

    <b-collapse
      id="payment-form-collapse"
      v-model="collapsed"
    >
      <ValidationObserver
        ref="observer"
        slim
      >
        <form
          :disabled="$wait.is(loader)"
          class="tw-w-full tw-mt-4"
          data-test="form"
          @submit.prevent="submitted"
        >
          <fieldset
            class="stripe-group tw-mb-6"
            data-test="stripe-group"
          >
            <legend
              class="tw-text-sm tw-font-medium tw-text-gray-700"
              v-text="$t('app.labels.cc_informations')"
              data-test="legend"
            />

            <ValidationProvider
              :name="$t('app.fields.cc_holder')"
              rules="required|max:255"
              slim
            >
              <template slot-scope="{ invalid, validated, errors }">
                <ctk-input-text
                  id="cardHolder"
                  v-model="formData.cardHolder"
                  name="cardHolder"
                  type="text"
                  :error="!$wait.is(loader) && invalid && validated"
                  :hint="errors && errors[0]"
                  :disabled="$wait.is(loader)"
                  :label="$t('app.labels.cc_holder')"
                  data-test="cardHolder"
                  class="tw-mb-6"
                  required
                  autocomplete="name"
                />
              </template>
            </ValidationProvider>

            <stripe-element-group
              :stripe="stripe"
              data-test="stripe-element-group"
            >
              <template
                slot-scope="{ stripeElements }"
              >
                <stripe-card-number
                  ref="card-number"
                  :disabled="$wait.is(loader)"
                  :stripe-elements="stripeElements"
                />
                <div class="tw-flex">
                  <stripe-card-expiry
                    ref="card-expiry"
                    :disabled="$wait.is(loader)"
                    :stripe-elements="stripeElements"
                    class="tw-w-1/2"
                  />
                  <stripe-card-cvc
                    ref="card-cvc"
                    :disabled="$wait.is(loader)"
                    :stripe-elements="stripeElements"
                    class="tw-w-1/2"
                  />
                </div>
              </template>
            </stripe-element-group>
          </fieldset>

          <div class="tw-flex tw-justify-between tw-mt-10">
            <ui-button
              :disabled="$wait.is(loader)"
              class="tw-mr-2"
              variant="link"
              type="reset"
              data-test="cancel-button"
              @click="collapsed = false"
            >
              {{ $t('account.buttons.cancel') }}
            </ui-button>
            <ui-button
              :loading="$wait.is(loader)"
              :disabled="$wait.is(loader)"
              variant="primary"
              type="submit"
              data-test="save-button"
            >
              {{ $t('account.password.buttons.save') }}
            </ui-button>
          </div>
        </form>
      </ValidationObserver>
    </b-collapse>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import { PaymentSource } from '@/resources'
  import { showToaster } from '@/services/Toaster'
  import { EventBus } from '@/services/EventBus'

  import CtkInputText from '@/components/CtkInputs/CtkInputText/index.vue'
  import StripeCardNumber from '@/components/Stripe/StripeCardNumber/index.vue'
  import StripeCardExpiry from '@/components/Stripe/StripeCardExpiry/index.vue'
  import StripeCardCvc from '@/components/Stripe/StripeCardCvc/index.vue'
  import StripeElementGroup from '@/components/Stripe/StripeElementGroup/index.vue'

  /**
   * @function PaymentSourcesForm
   * @param {object} stripe
   */
  export default {
    name: 'PaymentSourcesForm',
    components: {
      CtkInputText,
      StripeElementGroup,
      StripeCardNumber,
      StripeCardExpiry,
      StripeCardCvc
    },
    props: {
      stripe: {
        type: Object,
        default: null
      }
    },
    watch: {
      collapsed (v) {
        if (v && this.$matomo) {
          this.$matomo.trackEvent('Account', 'Payment Sources', 'Add Card Form Displayed')
        }

        /**
         * Make sure to clear all the fields whenever
         * the user opens or close the collapsible
         */
        if (this.$refs.observer && this.$refs.observer.reset) this.$refs.observer.reset()
        this.formData.cardHolder = null

        const {
          'card-number': cardNumber,
          'card-cvc': cardCvc,
          'card-expiry': cardExpiry
        } = this.$refs

        // @ts-ignore
        cardNumber && cardNumber.$refs['stripe-element'] && cardNumber.$refs['stripe-element'].clear()
        // @ts-ignore
        cardCvc && cardCvc.$refs['stripe-element'] && cardCvc.$refs['stripe-element'].clear()
        // @ts-ignore
        cardExpiry && cardExpiry.$refs['stripe-element'] && cardExpiry.$refs['stripe-element'].clear()
      }
    },
    data () {
      return {
        collapsed: false,
        loader: 'adding credit card',
        formData: {
          cardHolder: null
        }
      }
    },
    computed: {
      ...mapGetters('auth', [
        'getCid'
      ])
    },
    methods: {
      ...mapActions('auth', [
        'retrievePaymentSources'
      ]),
      toggle () {
        this.collapsed = !this.collapsed
      },
      /**
       * Called whenever the form is submitted
       * @function submitted
       */
      submitted () {
        if (this.$wait.is(this.loader)) return

        this.$refs.observer.validate()
          .then((/** @type {boolean} */ valid) => {
            if (!valid) {
              return
            }

            const { cardHolder } = this.formData

            if (this.$matomo) {
              this.$matomo.trackEvent('Account', 'Payment Sources', 'Add Card')
            }

            // @ts-ignore
            const cardElement = this.$refs['card-number'].$refs['stripe-element'].stripeElement

            this.$wait.start(this.loader)
            PaymentSource.save({
              cid: this.getCid
            })
              .then(paymentSourceRes => {
                this.stripe
                  .confirmCardSetup(paymentSourceRes.data.secret, {
                    payment_method: {
                      card: cardElement,
                      billing_details: {
                        name: cardHolder
                      }
                    }
                  })
                  .then((/** @type {any} */ setupRes) => {
                    if (setupRes.error && setupRes.error.type === 'validation_error') {
                      this.$wait.end(this.loader)
                      return
                    }

                    if (!setupRes.error) {
                      showToaster(this, this.$t('account.payment-sources.paragraphs.added'), {
                        type: 'success',
                        position: 'bottom-right'
                      })

                      EventBus.$emit('account:payment-sources:refresh-list')
                      this.retrievePaymentSources()
                        .then(() => {
                          this.collapsed = false
                        })
                        .finally(() => {
                          this.$wait.end(this.loader)
                        })
                    } else {
                      EventBus.$emit('dialogs:payment-source-error')
                      this.$wait.end(this.loader)
                    }
                  })
              })
              .catch(() => {
                this.$wait.end(this.loader)
              })
          })
      }
    }
  }
</script>

<style lang="scss" scoped>

  .payment-sources-form {
    &__button {
      &__icon {
        transition: transform 200ms;

        &--collapsed {
          transform: rotate(45deg);
        }
      }

      &:hover {
        background-color: transparent;
      }

      &:focus {
        box-shadow: none;
      }
    }
  }

</style>

<style lang="scss">

  .payment-sources-form .stripe-group .stripe-card-number {
    margin-bottom: -1px;
  }

  .payment-sources-form .stripe-group .stripe-card-expiry {
    margin-right: -1px;
  }

  .payment-sources-form .stripe-group .stripe-card-cvc {
    width: calc(50% + 1px);
  }

  .payment-sources-form .tabs-bar {
    border-bottom: 1px solid $divider;
  }

</style>
